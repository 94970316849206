import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Table,
  Card,
  Alert,
  Progress,
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input,
  Label,
  Badge,
  Modal
} from 'react-bootstrap'
import { Sparklines, SparklinesBars } from "react-sparklines";
import ModuleDataService from "../../services/ModuleService";
import UserDataService from "../../services/UserService";
import { Redirect, Link } from 'react-router-dom'
import Widget from "../../components/Widget";
import s from "./Tables.modules.scss";
import Moment from 'moment';
import DataTable from 'datatables.net';
import 'datatables.net-responsive';
import $ from 'jquery'
import jsPDF from "jspdf";

import water from "../../images/mark_water.jpeg";
import signed1 from "../../images/signed-1.png";
import signed2 from "../../images/signed-2.png";
import certificate from "../../images/certificate.jpeg";
import { logoutUser } from '../../actions/user';

import { BsPencilSquare as EditIcon, BsSearch as SearchIcon } from "react-icons/bs";

import { getAnalytics, logEvent } from "firebase/analytics";

class Modules extends Component {

  constructor(props) {
    super(props)
    this.retrieveModules = this.retrieveModules.bind(this);

    this.state = {
      modules: [],
      loading: true,
      authenticated: true,
      error: null,
      open: false,
      currentModule: null,
      mounted: false
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this.retrieveModules();
    this.retrieveUser();
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  retrieveUser() {

    var self = this;
    var id = this.props.id;

    UserDataService.getCurrentUser()
      .then(response => {
        if (this.state.mounted) {
          var user = response.data;

          self.setState({
            user: user
          });
        }
      })
      .catch(e => {
        if (e.response != null && e.response.status != null && e.response.status === 403) {
          console.log('unauthorized, logging out ...');
          self.setState({
            open: true,
            authenticated: false,
            loading: false
          })
        } else {
          this.setState({
            loading: false,
            error: e.response,
            errorNet: e.message
          })
        }
      });
  }

  generatePdf() {
    var user = this.state.user;
    var modules = this.state.modules;

    var lMargin = 35; //left margin in mm
    var rMargin = 5; //right margin in mm
    var pdfInMM = 280;  // width of A4 in mm

    var doc = new jsPDF('landscape');

    doc.setFont("times", "italic");

    doc.addImage(certificate, "JPEG", 0, 0, 300, 190);
    doc.addImage(water, "JPEG", 100, 55, 100, 100);


    doc.addImage(signed1, "JPEG", 30, 150, 70, 15);
    doc.addImage(signed2, "JPEG", 195, 150, 70, 15);

    var lang = this.props.lang;

    var title = this.context.t('certificate_of') + " " + user.category + "";

    doc.setFontSize(22);
    doc.text(title, 90, 43);

    doc.setFontSize(14);

    doc.setTextColor("black");

    doc.text(this.context.t('user.name') + ": " + user.firstName + " " + user.lastName, 25, 60);

    doc.text(this.context.t('user.national_id') + ": " + user.nationalId, 25, 65);

    doc.text(this.context.t('user.company') + ": " + user.company, 25, 70);

    doc.text(this.context.t('header.modules') + ": ", 25, 83);


    var line = 93;

    doc.setFontSize(10);

    for (var i = 0; i < modules.length; i++) {
        var module = modules[i];

        if(module.status == "APPROVED"){

            var moduleName = module.module.name;

            doc.text(moduleName, 35, line);

            var status = this.context.t('modules.approved') + " - " + module.percentDone + "% - " + module.countAnswersCorrect + " " + this.context.t('modules.of') + " " + module.countQuestions;
            if (module.status == "APPROVED") {
              doc.setTextColor("green");
              doc.text(status, 95, line);
            } else {
              doc.setTextColor("red");
              doc.text(status, 95, line);
            }

            doc.setTextColor("black");

            var fecha = this.context.t('modules.finish_date') + ": " + Moment(module.createDate).format('DD/MM/YYYY');
            doc.text(fecha, 160, line);

            var fechaExpiracion = this.context.t('modules.expiration_date') + ": " +  Moment(module.expirationDate).format('DD/MM/YYYY');
            doc.text(fechaExpiracion, 220, line);

            line = line + 5;
        }
    }



    return doc;
  }

  print(event) {
    var user = this.state.user;
    var doc = this.generatePdf();

    doc.save(user.nationalId + "_" + user.category + "_" + user.company + "_" +new Date().getTime() + ".pdf");
  }

  send(event) {

    var doc = this.generatePdf();
    var out = doc.output();

    var body = {
      "certificate.pdf": btoa(out)
    };

    this.setState({
      loading: true
    });

    ModuleDataService.sendProof(this.props.lang, body).then(response => {
        this.setState({
            loading: false
        });
        this.loadTable();
    })
    .catch(e => {
        this.setState({
            loading: false
        })
        this.loadTable();
    });

  }

  editModule = (data) => {

    let path = '/app/edit-module/' + data.currentModule.id;

    this.props.history.push(path);
  }

  viewDetail = (data) => {


    let path = '/app/modules/' + data.currentModule.id;

    this.props.history.push(path);
  }

  retrieveModules() {

    var self = this;

    var lang = this.props.lang;

    ModuleDataService.getAll(lang)
      .then(response => {
        if (this.state.mounted) {
          var modules = response.data;

          self.setState({
            modules: modules,
            loading: false
          });

          if (modules.length > 0) {
            setTimeout(self.loadTable, 200);
          }

          console.log(response.data);
        }
      })
      .catch(e => {
        if (e.response != null && e.response.status != null && e.response.status === 403) {
          console.log('unauthorized, logging out ...');
          self.setState({
            open: true,
            authenticated: false,
            loading: false
          })
        } else {
          this.setState({
            loading: false,
            error: e.response,
            errorNet: e.message
          })
        }
      });
  }

  handleClose(reason) {
    if (reason && reason == "backdropClick") return;
    this.setState({
      open: false
    })
    localStorage.removeItem("id_token");
    window.location.reload();
  }

  loadTable = () => {
    this.modulesTable = $('#modules-table').DataTable({
      "ordering": true,
      "destroy": true,
      "responsive": true,
      "lengthMenu": [[5, 10, 20, -1], [5, 10, 20, "Todos"]],
      "columns": [
        {
          "sortable": true
        },
        {
          "sortable": false
        },
        {
          "sortable": false
        },
        {
          "sortable": false
        },
        {
          "sortable": false
        }
      ],
      "columnDefs": [
        {
          targets: [0],
          className: 'mdl-data-table__cell--non-numeric'
        }
      ],
      "language": {
        "sProcessing": this.context.t("table.sProcessing"),
        "sLengthMenu": this.context.t("table.sLengthMenu"),
        "sZeroRecords": this.context.t("table.sZeroRecords"),
        "sEmptyTable": this.context.t("table.sEmptyTable"),
        "sInfo": this.context.t("table.sInfo"),
        "sInfoEmpty": this.context.t("table.sInfoEmpty"),
        "sInfoFiltered": this.context.t("table.sInfoFiltered"),
        "sInfoPostFix": "",
        "sSearch": this.context.t("table.sSearch"),
        "sUrl": "",
        "sInfoThousands": ",",
        "sLoadingRecords": this.context.t("table.sLoadingRecords"),
        "oPaginate": {
          "sFirst": this.context.t("table.sFirst"),
          "sLast": this.context.t("table.sLast"),
          "sNext": this.context.t("table.sNext"),
          "sPrevious": this.context.t("table.sPrevious")
        },
        "oAria": {
          "sSortAscending": this.context.t("table.sSortAscending"),
          "sSortDescending": this.context.t("table.sSortDescending")
        }
      }
    });
  }

  switchStatus(module) {
    if (module.expired) {
      return <td className={"pl-0 text-danger fw-normal"}>
        {this.context.t('modules.expired')}
      </td>;
    } else {
      switch (module.status) {
        case 'APPROVED':
          return <td className={"pl-0 text-success fw-normal"}>
            {this.context.t('modules.approved')} - {module.percentDone}% - {module.countAnswersCorrect} {this.context.t('modules.of')} {module.countQuestions}
          </td>;
        case 'DISAPPROVED':
          return <td className={"pl-0 text-danger fw-normal"}>
            {this.context.t('modules.disapproved')} - {module.percentDone}% - {module.countAnswersCorrect} {this.context.t('modules.of')} {module.countQuestions}
          </td>;
        default:
          return <td className={"pl-0 text-warning fw-normal"}>
            {this.context.t('modules.pending')}
          </td>;
      }
    }

  }

  switchStatusActions(madeModule) {
    if (madeModule.expired) {
      return <Link to={'/app/modules/' + madeModule.module.id}>
        <Button variant="info" className="mr-xs" size="sm">
          {this.context.t('modules.make')}
        </Button>
      </Link>;
    } else {
      switch (madeModule.status) {
        case 'APPROVED':
          return <div>
                 <Link to={'/app/results/' + madeModule.id}>
                   <Button variant="info" className="mr-xs" size="sm">
                     <SearchIcon />
                   </Button>
                 </Link>
               </div>;
        case 'DISAPPROVED':
          return <div>
            <Link to={'/app/modules/' + madeModule.module.id}>
              <Button variant="info" className="mr-xs" size="sm">
                {this.context.t('modules.make')}
              </Button>
            </Link>
          </div>;
        default:
          return <div>
            <Link to={'/app/modules/' + madeModule.module.id}>
              <Button variant="info" className="mr-xs" size="sm">
                {this.context.t('modules.make')}
              </Button>
            </Link>
          </div>;
      }
    }

  }

  areAllModulesApproved() {
    const { modules } = this.state;
    return modules.every((module) => module.status === 'APPROVED' && !module.expired);
  }

  render() {
    const analytics = getAnalytics();
    logEvent(analytics, 'modules_page');

    const { modules, currentModule, openModal } = this.state;

    const allModulesApproved = this.areAllModulesApproved();

    if (this.state.loading) {
      return (
        <div>
          Cargando...
        </div>
      )
    }

    if (!this.state.authenticated) {
      return (
        <Modal
          show={this.state.open}
          onHide={(_, reason) => { this.handleClose(reason) }}>
          <Modal.Header closeButton>
            <Modal.Title>{this.context.t('session.expired')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.context.t('session.msg')} <br />
            {this.context.t('session.redirect')}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={e => { this.handleClose() }} autoFocus>
              {this.context.t('accept')}
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    let error;

    if (this.state.errorNet) {
      error = <Alert variant="danger" style={{ marginBottom: "20px" }}>
        {this.context.t('error.unexpected')}
      </Alert>;
    }

    if (this.state.error) {
      error = <Alert variant="danger" style={{ marginBottom: "20px" }}>
        <strong>{this.state.error.status}</strong>{this.context.t('error.unexpected')}
      </Alert>;
    }


    return (
      <div className={s.root}>
          {allModulesApproved && (
        <Row>
          <Col xs={12} lg={6}>
            <button className="btn btn-lg btn-block button button--primary" tabIndex="2" type="button" onClick={e => { this.print(e) }}>{this.context.t('modules.download')}</button>
            <br /><br />
          </Col>
          <Col xs={12} lg={6}>
            <button className="btn btn-lg btn-block button button--primary" tabIndex="2" type="button" onClick={e => { this.send(e) }}>{this.context.t('modules.send')}</button>
            <br /><br />
          </Col>
        </Row>
       )}

        <Row>
          <Col sm={12}>
            {error}
            <Widget style={{ paddingTop: "20px" }}>
              <table id="modules-table" className="display responsive nowrap" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th key={1} scope="col" className={"pl-0"}>
                      {this.context.t('modules.name')}
                    </th>
                    <th key={2} scope="col" className={"pl-0"}>
                      {this.context.t('modules.status')}
                    </th>
                    <th key={3} scope="col" className={"pl-0"}>
                      {this.context.t('modules.finish_date')}
                    </th>
                    <th key={4} scope="col" className={"pl-0"}>
                      {this.context.t('modules.expiration_date')}
                    </th>
                    <th key={5} scope="col" className={"pl-0"}>

                    </th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {modules && modules.length > 0 ? modules && modules.map((currentModule,index) => {
                    return (
                      <tr key={index} data-id={currentModule.id}>
                        <td className={"pl-0 fw-thin"}>
                          {currentModule.questionnaire != null ?
                            currentModule.questionnaire.title
                            :
                            currentModule.module.name
                          }
                        </td>
                        {this.switchStatus(currentModule)}
                        <td className={"pl-0 fw-thin"}>
                          {currentModule.createDate != null ?
                            Moment(currentModule.createDate).format(this.context.t('date.format'))
                            :
                            ""
                          }

                        </td>
                        <td className={"pl-0 fw-thin"}>
                          {currentModule.expirationDate != null && currentModule.status == 'APPROVED' ?
                            Moment(currentModule.expirationDate).format(this.context.t('date.format'))
                            :
                            ""
                          }

                        </td>
                        <td className={"pl-0 fw-thin"} style={{ textAlign: "center" }}>
                          {this.switchStatusActions(currentModule)}
                        </td>
                      </tr>
                    )
                  }) :
                    <tr>
                      <td colspan="5">
                        <div className="alert alert-info" role="alert">
                          <i className="fa fa-info mx-2"></i> {this.context.t('table.sEmptyTable')}
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }
}

Modules.contextTypes = {
  t: PropTypes.func.isRequired
}

export default connect(state => ({
  lang: state.i18nState.lang
}))(Modules)

